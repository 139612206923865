import React, {useEffect, useState} from 'react';
import q from './Orders.module.css'
import {useDispatch, useSelector} from "react-redux";
import Popup from "../../UI/Popup/Popup";
import {ImCheckmark, ImCross, ImMobile, ImStopwatch, ImSpinner9} from "react-icons/im";
import takeADay from '../../js/takeADay';
import takeAZeroTime from "../../js/takeAZeroTime";
import takeADate from "../../js/takeADate";
import {GiHammerBreak} from "react-icons/all";


const Orders = () => {
    const orders = useSelector(state => state.order);
    const [ordersAct, setOrdersAct] = useState(orders.filter(order => order.active === true));
    const dispatch = useDispatch();
    const [popupShow, setPopupShow] = useState(false);
    const [mountPopup, setMountPopup] = useState(false);
    const currentDate = Date.now();
    const zeroDate = takeAZeroTime(currentDate);

    const [prolongation, setProlongation] = useState(false);
    const [moneyback, setMoneyBack] = useState(false);
    const [condition, setCondition] = useState(false);



    useEffect(() => resetForm(), [moneyback, prolongation]);

    const dss = (arg) => {
        return arg * 8.64e+7;
    };

    useEffect(() => {
        orders.sort((x, y) => x.termDate - y.termDate);
        setOrdersAct(orders.filter(order => order.active === true));
    }, [orders]);

    const [form, setForm] = useState({
        id: '',
        name: '',
        address: '',
        phone: '',
        item: 'Продлевание - ' + '',
        itemId: '',
        startDate: '',
        termDate: '',
        fullDate: '',
        price: 0,
        active: false,
        add: true,
    });

    const changeHandler = (event) => {
        if (event.target.name === 'termDate') {
            setForm({...form, termDate: dss(Number(event.target.value))})
        } else if (event.target.name === 'price') {
            setForm({...form, [event.target.name]: Number(event.target.value)})
        }
    };


    function getItem(arg) {
        return orders.filter(o => o.id === arg)[0];
    }

    function getDelta(arg) {
        return arg.termDate - arg.startDate;
    }


    const [ppId, setPpId] = useState(false);


    const showPP = (arg) => {

        if (arg) {
            setMountPopup(true);
            setPpId(arg.id);
            setTimeout(() => setPopupShow(true), 10);
        } else {
            // dispatch({type: "HIDE_POPUP"});
            setPopupShow(false);
            setTimeout(() => {
                setMountPopup(false);
                setForm({...form, price: 0});
                setProlongation(false);
                // resetForm();
                setMoneyBack(false);
                setProlongation(false);
            }, 500);
        }
    };

    const orderComplete = (arg) => {
        let newOrders = orders.map(i => {
            if (i.id === arg.id) {
                i.active = false;
                return i
            } else return i
        });
        showPP();
        dispatch({type: "ORDERS_UPDATE", payload: newOrders});
        dispatch({type: "UPDATE_ITEM", payload: {id: arg.itemId, condition: condition}})
    };

    function idkn() {
        if (moneyback) {
            return "возвр_"
        }
        if (prolongation) {
            return "пр_"
        }
    }

    function sprc(arg) {
        if (moneyback) {
            return -Math.abs(arg)
        }
        if (prolongation) {
            return arg
        }
    }

    const sendNewDate = (arg) => {
        let adds = {
            name: arg.name,
            address: arg.address,
            phone: arg.phone,
            item: idkn() + getItem(ppId).item,
            itemId: arg.itemId,
            startDate: zeroDate,
            termDate: arg.termDate + form.termDate,
            fullDate: currentDate,
            id: Date.now(),
            price: sprc(form.price),
            active: false,
            add: true,
        };

        if (form.termDate) {
            let newOrders = orders.map(order => {
                if (order.id === arg.id) {
                    if (prolongation) {
                        return {...order, termDate: order.termDate + form.termDate};
                    } else {
                        return {...order, termDate: order.termDate - form.termDate};
                    }
                } else {
                    return order
                }
            });
            dispatch({type: "ORDERS_UPDATE", payload: newOrders});
        }
        dispatch({type: "ADD_ORDER", payload: adds});
        if (!moneyback) {
            showPP();
        }
        if (moneyback) {
            setMoneyBack(false)
        }
    };

    function resetForm() {
        setForm({
            id: '',
            name: '',
            address: '',
            phone: '',
            // item: '',
            itemId: '',
            startDate: '',
            termDate: '',
            fullDate: '',
            price: 0,
            active: false,
            add: true,
        });

    }

    const dutyFunc = (arg) => {
        let newOrders = orders.map(i => {
            if (i.id === arg) {
                i.duty = false;
                return i
            } else return i
        });
        // showPP();
        dispatch({type: "ORDERS_UPDATE", payload: newOrders});
    };

    return (
        <>
            <div className={popupShow
                ? [q.ordersField, q.hidden].join(' ')
                : q.ordersField
            } onClick={() => {
                if (popupShow) {
                    showPP()
                }
            }}>
                {ordersAct.length
                    ? ordersAct.map(o => (
                        <div key={o.id} className={q.box} onClick={(e) => {
                            e.stopPropagation();
                            showPP(o);
                        }}>
                            <div className={q.description}>
                                <strong><span>{o.item}</span></strong>
                            </div>
                            <div className={q.description}>
                                {((o.termDate - zeroDate) / 8.64e+7) > 0 &&
                                <span><span>Возврат через</span> <span><strong>{(o.termDate - zeroDate) / 8.64e+7} </strong></span> {takeADay((o.termDate - zeroDate) / 8.64e+7)}</span>}
                                {((o.termDate - zeroDate) / 8.64e+7) === 0 &&
                                <span><strong>Возврат сегодня</strong></span>}
                                {((o.termDate - zeroDate) / 8.64e+7) < 0 && <span
                                    style={{color: 'red'}}>Просрочен на <strong>{Math.abs(zeroDate - o.termDate) / 8.64e+7} </strong>
                                    {takeADay(Math.abs(zeroDate - o.termDate) / 8.64e+7)}</span>}
                                {o.termDate - zeroDate >= 0
                                    ? <div style={{
                                        color: 'green',
                                        marginLeft: 'auto'
                                    }}><strong>{takeADate(o.termDate)}</strong></div>
                                    : <span style={{
                                        color: 'red',
                                        marginLeft: 'auto'
                                    }}><strong>{takeADate(o.termDate)}</strong></span>
                                }
                            </div>
                            <div className={q.description}>
                                {o.duty && <div className={q.dutyMarker}>- БЕЗ ОПЛАТЫ -</div>}
                            </div>
                        </div>
                    ))
                    : <p>Заказов ещё нет</p>
                }
            </div>
            {mountPopup &&
            <Popup sp={popupShow} showPP={showPP}>
                <div className={q.popupItm}>
                    <ImCross className={q.clz} onClick={() => showPP(false)}/>
                    <div
                        className={q.cardDate}>от: {takeADate(getItem(ppId).fullDate)}
                        <span style={{marginLeft: '10px'}}>срок аренды</span>
                        <strong
                            style={{marginLeft: '5px'}}>{(getDelta(getItem(ppId)) / 8.64e+7) - 1} {takeADay((getDelta(getItem(ppId)) / 8.64e+7) - 1)}</strong>
                    </div>

                    <div className={q.cardItems}>
                        <div className={q.cardItemsd}>
                            <div className={q.cardName}><strong>{getItem(ppId).name}</strong></div>
                            <div className={q.cardAddr}>{getItem(ppId).address}</div>

                        </div>
                        <a className={q.cardCall} href={`tel:+375${getItem(ppId).phone}`}>
                            <ImMobile className={q.cardCallIco}/>
                        </a>

                        {/*------------------------------------------*/}

                        <label className={q.checkBox}>
                            <input
                                type="checkbox"
                                className={q.checkBoxInput}
                                name='duty'
                                // disabled={moneyback}
                                onChange={() => {
                                    setProlongation(!prolongation);
                                    if (moneyback) {
                                        setMoneyBack(!moneyback)
                                    }
                                }}
                                checked={prolongation}
                            />
                            <div className={q.checkBoxInputDiv}>
                                <ImStopwatch className={q.timerBtn}/>
                            </div>
                        </label>

                        {/*------------------------------------------*/}

                        {!getItem(ppId).duty &&
                        <label className={q.checkBox}>
                            <input
                                type="checkbox"
                                className={q.checkBoxMB}
                                name='moneyBack'
                                onChange={() => {
                                    if (prolongation) {
                                        setProlongation(!prolongation)
                                    }
                                    setMoneyBack(!moneyback)
                                }}
                                checked={moneyback}
                            />
                            <div className={q.recBtn}>
                                <ImSpinner9 className={q.recBTN}/>
                            </div>
                        </label>
                        }

                        {/*------------------------------------------*/}

                    </div>
                    {getItem(ppId).duty &&
                    <div className={q.cardNameD} onClick={() => dutyFunc(getItem(ppId).id)}><strong>- ОПЛАТИЛИ
                        -</strong>
                    </div>}
                    <div className={prolongation || moneyback
                        ? q.cardClose
                        : [q.cardClose, q.prolog].join(' ')
                    }>
                        <label className={q.checkBoxDestr}>
                            <input
                                type="checkbox"
                                className={q.checkBoxInpDestr}
                                name='duty'
                                disabled={moneyback}
                            />
                            <div className={q.destBtn} onClick={() => {
                                if(!condition){
                                    setCondition(getItem(ppId).itemId)
                                }
                                else{
                                    setCondition(false)
                                }
                            }}>
                                <GiHammerBreak className={q.ds}/>
                            </div>
                        </label>
                        <div className={q.okBtn} onClick={() => orderComplete(getItem(ppId))}>
                            <ImCheckmark className={q.ok}/>
                            Выполнено
                        </div>
                        {/*------------------------------------------------*/}

                    </div>

                    <div className={prolongation || moneyback
                        ? [q.cardProlongation, q.prolog].join(' ')
                        : q.cardProlongation
                    }>
                        <input type="number"
                               name="termDate"
                               onChange={changeHandler}
                               value={form.termDate && form.termDate / 8.64e+7}
                               placeholder={moneyback
                                   ? "Вычесть дни"
                                   : "Продлить на"
                               }
                        />

                        <input type="number"
                               name="price"
                               placeholder={moneyback
                                   ? "Вернуть ср-ва"
                                   : "Доплата"
                               }
                               autoComplete="off"
                               onChange={changeHandler}
                               value={form.price || form.price !== 0 && form.price}
                        />
                        <button onClick={() => sendNewDate(getItem(ppId))}>
                            <ImCheckmark className={q.okBTN}/>
                        </button>
                    </div>

                </div>
            </Popup>
            }
        </>
    );
};

export default Orders;