import React from 'react';
import q from './Panel.module.css';
import {useNavigate, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";

const Panel = () => {
    const panelButtons = useSelector(state => state.panelButtons);
    const isAuth = useSelector(state => state.auth.isAuth);


    let navigate = useNavigate();
    const location = useLocation();

    const sf = (arg) => {
        if (arg !== location.pathname) {
            navigate(arg)
        }
    };

    return (
        <div className={isAuth ? q.panel : [q.panel, q.hide].join(' ')}>
            {isAuth &&
            <div className={q.panelItems}>
                {panelButtons.map(button => (
                    <div className={q.button} key={button.id} onClick={() => sf(button.path)}>
                        {button.ico}
                        <span className={q.span}>{button.title}</span>
                    </div>
                ))}
            </div>
            }
        </div>
    );
};

export default Panel;