import React from 'react';
import q from './Main.module.css'
import {Navigate, Route, Routes} from "react-router-dom";
import StartPage from "../../pages/StartPage/StartPage";
import {useSelector} from "react-redux";

const Main = () => {

    const panelButtons = useSelector(state => state.panelButtons);
    const isAuth = useSelector(state => state.auth.isAuth);

    return (
        <div className={
            isAuth
                ? q.main
                : [q.main, q.full].join(' ')
        }>
            <Routes>
                <Route exact path='/' element={<StartPage/>}/>
                {isAuth &&
                panelButtons.map(item => (
                    <Route key={item.id} exact path={item.path} element={item.page}/>
                ))}
                <Route path='*' element={<Navigate to='/'/>}/>
            </Routes>
        </div>
    );
};

export default Main;