const takeAHour = (arg) => {
    let a, b;
    if (new Date(arg).getHours() < 10) {
        a = '0' + new Date(arg).getHours()
    } else {
        a = new Date(arg).getHours()
    }
    if (new Date(arg).getMinutes() < 10) {
        b = '0' + new Date(arg).getMinutes()
    } else {
        b = new Date(arg).getMinutes()
    }
    return [a, b].join(':')
};

export default takeAHour;