import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {ImUsers, ImFileText, ImBooks, ImList, ImCalendar} from "react-icons/im";
import React, {useState} from "react";
import q from '../components/Panel/Panel.module.css'
import New from "../pages/New/New";
import Orders from "../pages/Orders/Orders";
import Clients from "../pages/Clients/Clients";
import Stats from "../pages/Stats/Stats";
import Storage from "../pages/Storage/Storage";
import {isAuthReducer} from "./reducers/isAuthReducer";


const panelButtons = [
    {id: 1, title: 'новый', ico: <ImFileText className={q.icon}/>, path: 'new', page: <New/>},
    {id: 2, title: 'заказы', ico: <ImBooks className={q.icon}/>, path: 'orders', page: <Orders/>},
    {id: 3, title: 'клиенты', ico: <ImUsers className={q.icon}/>, path: 'clients', page: <Clients/>},
    {id: 4, title: 'статистика', ico: <ImCalendar className={q.icon}/>, path: 'stats', page: <Stats/>},
    {id: 5, title: 'склад', ico: <ImList className={q.icon}/>, path: 'storage', page: <Storage/>},
];

const items = [
    {
        id: 1,
        title: 'Батут Nord диаметр 140 см',
        price: 1500,
        totalCash: 0,
        busy: false,
        condition: true,
    },
    {
        id: 2,
        title: 'Кухня двусторонняя Fisher Price',
        price: 1500,
        totalCash: 0,
        busy: false,
        condition: true,
    },
    {
        id: 3,
        title: 'Игровой комплекс Little Tikes "Волшебный домик"',
        price: 1500,
        totalCash: 0,
        busy: false,
        condition: true,
    },
    {
        id: 4,
        title: 'Двухсторонний развивающий домик Fisher Price на русском языке',
        price: 1500,
        totalCash: 0,
        busy: true,
        condition: true,
    },
    {
        id: 5,
        title: 'Игровая площадка и игровой комплекс, а также домик и ракета. Feber.',
        price: 1500,
        totalCash: 0,
        busy: false,
        condition: true,
    },
    {
        id: 6,
        title: 'Электрокачели шезлонг Nuovita Cullare',
        price: 1500,
        totalCash: 0,
        busy: false,
        condition: true,
    },
    {
        id: 7,
        title: 'Развивающий коврик трансформер Yookidoo «Мои друзья»',
        price: 1500,
        totalCash: 0,
        busy: true,
        condition: true,
    },
    {
        id: 8,
        title: 'Прогулочная коляска Baby Jogger City Mini Zip',
        price: 1500,
        totalCash: 0,
        busy: true,
        condition: true,
    },
];

const orders = [
    {
        name: "Иванов",
        address: "Ивановская 34",
        phone: 292223344,
        item: 'Двухсторонний развивающий домик Fisher Price на русском языке',
        itemId: 4,
        startDate: 1636923600000,
        termDate: 1637182800000,
        fullDate: 1637193600000,
        id: 1,
        price: 40,
        active: true,
        duty: false,
    },
    {
        name: "Петров",
        address: "Петровская 12",
        phone: 333485698,
        item: 'Прогулочная коляска Baby Jogger City Mini Zip',
        itemId: 8,
        startDate: 1637096400000,
        termDate: 1637614800000,
        fullDate: 1637139600000,
        id: 2,
        price: 60,
        active: true,
        duty: false,
    },
    {
        name: "Сидоров",
        address: "Набережная 110/95",
        phone: 258966641,
        item: 'Развивающий коврик трансформер Yookidoo «Мои друзья»',
        itemId: 7,
        startDate: 1637614800000,
        termDate: 1638910800000,
        fullDate: 1637619232168,
        id: 3,
        price: 50,
        active: true,
        duty: true,
    }
];

const clients = [
    {
        name: "Иванов",
        address: "Ивановская 34",
        phone: 292223344,
        orders: 1,
        id: 292223344,
        status: "good",
    },
    {
        name: "Петров",
        address: "Петровская 12",
        phone: 333485698,
        orders: 1,
        id: 333485698,
        status: "good",
    },
    {
        name: "Сидоров",
        address: "набережная 110/95",
        phone: 258966641,
        orders: 1,
        id: 258966641,
        status: "good",
    }
];

const showPopup = false;


const popupReducer = (state = showPopup, action) => {
    switch (action.type) {
        case "SHOW_POPUP":
            return true;
        case "HIDE_POPUP":
            return false;
        default:
            return state
    }
};

const itemsReducer = (state = items, action) => {
    switch (action.type) {
        case "ADD_ITEM":
            return [...state, action.payload];
        case "UPDATE_ITEM":
            return state.map(item => {
                if (item.id === action.payload.id) {
                    if (action.payload.cash) {
                        return {...item, totalCash: item.totalCash + action.payload.cash, busy: true}
                    } else if (action.payload.condition) {
                        return {...item, busy: false, condition: false}
                    } else {
                        return {...item, busy: false}
                    }
                } else {
                    return item
                }
            });
        case "REMOVE_ITEM":
            return state.filter(i => i.id !== action.payload);
        case "REPAIR_ITEM":
            return state.map(item => {
                if (item.id === action.payload.id) {
                    return {...item, price: item.price + action.payload.price, condition: true};
                }
                else {
                    return item
                }
            });
        default:
            return state
    }
};

const orderReducer = (state = orders, action) => {
    switch (action.type) {
        case "ADD_ORDER":
            return [...state, action.payload];
        case "ORDERS_UPDATE":
            return action.payload;
        default:
            return state
    }
};

const clientReducer = (state = clients, action) => {
    switch (action.type) {
        case "ADD_CLIENT":
            return [...state, action.payload];
        case "CLIENT_ADD_ORDER":
            return state;
        default:
            return state
    }
};

const panelButtonsReducer = (state = panelButtons) => {
    return state
};


const rootReducer = combineReducers({
    auth: isAuthReducer,
    panelButtons: panelButtonsReducer,
    items: itemsReducer,
    order: orderReducer,
    clients: clientReducer,
    ppr: popupReducer,
});

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware())
);