let defaultAuth = {isAuth: false};

export const isAuthReducer = (state = defaultAuth, action) => {
    switch (action.type) {
        case "LOGIN":
            return {...state, isAuth: true};
        case "LOGIN_OUT":
            return {...state, isAuth: false};
        default:
            return state
    }
};