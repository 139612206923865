import React, {useEffect, useState} from 'react';
import q from "./Stats.module.css";
import {useSelector} from "react-redux";
import takeAMoney from "../../js/takeAMoney";
import takeADate from "../../js/takeADate";
import Popup from "../../UI/Popup/Popup";
import {ImCross} from "react-icons/im";
import takeAHour from "../../js/takeAHour";

const Stats = () => {


    const orders = useSelector(state => state.order);
    const [popupShow, setPopupShow] = useState(false);
    const [mountPopup, setMountPopup] = useState(false);
    const [showInfo, setShowInfo] = useState([]);
    const [day, setDay] = useState('');


    // console.log(showInfo)


    function summ(arg) {
        return arg.map(q => q.price).reduce((x, y) => x + y)
    }


    const showPP = (arg) => {
        if (arg) {
            // orders.sort((x, y) => y.id - x.id);
            setDay(arg);
            setShowInfo(orders.filter(o => o.startDate === arg).sort((x, y) => y.id - x.id));
            setMountPopup(true);
            setTimeout(() => setPopupShow(true), 10);
        } else {
            setPopupShow(false);
            setTimeout(() => {
                setMountPopup(false);
            }, 600);
        }
    };


    const getMoney = (arg) => {
        return orders.filter(to => to.startDate === arg).map(q => q.price).reduce((x, y) => x + y, 0);
    };

    let dateStat = [];

    function ds() {
        orders.forEach(o => {
            if (!dateStat.includes(o.startDate)) {
                dateStat.push(o.startDate);
            }
        });
        // dateStat.sort((x,y) => y.localeCompare(x));
        dateStat.sort((x, y) => y - x);
    }

    ds();
    useEffect(() => {
        ds();
    }, [orders]);

    return (
        <>
            <div className={q.statField}>
                {dateStat.length
                    ?
                    dateStat.map(dtst => (
                        <div className={q.box} key={dtst} onClick={() => showPP(dtst)}>
                            <span><strong>{takeADate(dtst)}</strong></span>
                            <span
                                style={{marginLeft: '7%'}}>Выручка: <strong>{getMoney(dtst)}</strong> {takeAMoney(getMoney(dtst))}</span>
                        </div>
                    ))
                    : <p>...Пусто... ;-)</p>
                }
            </div>
            {mountPopup &&
            <Popup sp={popupShow} showPP={showPP}>
                <div className={q.popupItm}>
                    <ImCross className={q.clz} onClick={() => showPP(false)}/>
                    <div className={q.statsDate}>
                        <h3>Статистика
                            за {[new Date(day).getDate(), new Date(day).getMonth() + 1, new Date(day).getFullYear()].join('.')}</h3>
                    </div>
                    <div className={q.statInfoField}>
                        {showInfo.map(ord => (
                            <div className={q.statFieldItm} key={ord.id}>
                                <span>{takeAHour(ord.fullDate)}</span>
                                <span className={q.mstspn}>{ord.item}</span>
                                <span className={q.lstspn}>{Math.abs(ord.price)} {takeAMoney(ord.price)}</span>
                            </div>
                        ))}
                    </div>
                    <div className={q.itog}>
                        Итого за день:
                        <strong style={{margin: '0 7px'}}> {summ(showInfo)} </strong>
                        <span> {takeAMoney(summ(showInfo))}</span>
                    </div>
                </div>
            </Popup>
            }
        </>
    );
};

export default Stats;