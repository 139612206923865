// const takeAMoney = (arg) => {
//     const n = arg % 10;
//     if (arg >= 11 && arg <= 20) {
//         return 'рублей'
//     } else if (n === 1) {
//         return 'рубль'
//     } else if (n >= 2 && n <= 4) {
//         return 'рубля'
//     } else if (n >= 5 && n <= 9) {
//         return 'рублей'
//     } else if (n === 0) {
//         return 'рублей'
//     }

const takeAMoney = (arg) => {
    let n = Math.abs(arg);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return 'рублей'
    }
    n %= 10;
    if (n === 1) {
        return 'рубль'
    }
    if (n >= 2 && n <= 4) {
        return 'рубля'
    }
    return 'рублей'
};

export default takeAMoney;

