import React, {useEffect, useRef, useState} from 'react';
import q from './New.module.css'
import {useDispatch, useSelector} from "react-redux";
import {ImHistory, ImCross, ImCheckmark} from "react-icons/im";
import Popup from "../../UI/Popup/Popup";
import takeAMoney from "../../js/takeAMoney";
import takeAZeroTime from "../../js/takeAZeroTime";
import takeADate from "../../js/takeADate";

const New = () => {

        const [itField, setItField] = useState(false);
        const [popupShow, setPopupShow] = useState(false);
        const [mountPopup, setMountPopup] = useState(false);
        const items = useSelector(state => state.items);
        const clients = useSelector(state => state.clients);
        const [tempItems, setTempItems] = useState(items);
        const [choice, setChoice] = useState(false);
        const dispatch = useDispatch();
        const currentDate = Date.now();

        const selectInput = useRef();

        const showPP = (arg) => {
            if (arg) {
                setMountPopup(true);
                setTimeout(() => setPopupShow(true), 10);
            } else {
                setPopupShow(false);
                setTimeout(() => setMountPopup(false), 600);
            }
        };


        const [form, setForm] = useState({
            name: '',
            address: '',
            phone: '',
            item: '',
            itemId: '',
            startDate: '',
            termDate: 'Срок аренды',
            fullDate: '',
            id: '',
            price: '',
            active: true,
            duty: false,
        });

        const changeHandler = (event) => {
            if (event.target.name === 'duty') {
                setForm({...form, duty: !form.duty});
                return
            }
            if (event.target.name === 'phone' && event.target.value.length <= event.target.maxLength) {
                setForm({...form, [event.target.name]: Number(event.target.value)})
            } else if (event.target.name === 'price' || event.target.name === 'termDate') {
                setForm({...form, [event.target.name]: Number(event.target.value)})
            } else if (event.target.name !== 'phone') {
                setForm({...form, [event.target.name]: event.target.value})
            }
        };

        useEffect(() => {
            if (form.item !== '') {
                setTempItems(items.filter(i => i.condition).filter(i => i.busy !== true).filter(item => item.title.toLowerCase().trim().includes(form.item.toLowerCase().trim())));
            } else {
                setTempItems(items)
            }
        }, [form.item, items]);

        const handleClick = (arg) => {
            setForm({...form, item: arg.title, itemId: arg.id});
            setChoice(true)
        };

        const sort = (e) => {
            setForm({...form, item: e.target.value});
            if (choice) {
                setChoice(false);
            }
        };

        useEffect(() => {
            if (!choice) {
                if (form.item !== '' && form.item != null) {
                    setItField(true);
                } else {
                    setItField(false)
                }
            } else {
                setItField(false)
            }
        }, [form.item, choice]);

        const dss = (arg) => takeAZeroTime(currentDate) + (arg * 8.64e+7);


        const createOrder = () => {
            setForm({
                ...form,
                startDate: takeAZeroTime(currentDate),
                fullDate: currentDate,
                id: Date.now(),
            });
            if (!popupShow) {
                showPP(true);
            }
        };


        let userR;
        const [clientsPhone, setClientsPhone] = useState(clients);

        useEffect(() => {
            setClientsPhone(clients.map(i => i.phone));
            // clients.map(i => i.phone)
        }, [clients]);


        const dataSelect = [
            {value: 'Срок аренды', title: 'Срок аренды', disabled: true},
            {value: dss(29), title: '4 недели', disabled: false},
            {value: dss(15), title: '2 недели', disabled: false},
            {value: dss(8), title: '1 неделя', disabled: false},
            {value: dss(7), title: '6 дней', disabled: false},
            {value: dss(6), title: '5 дней', disabled: false},
            {value: dss(5), title: '4 дня', disabled: false},
            {value: dss(4), title: '3 дня', disabled: false},
            {value: dss(3), title: '2 дня', disabled: false},
            {value: dss(2), title: '1 день', disabled: false},
        ];

        const addOrder = () => {
            dispatch({type: "ADD_ORDER", payload: form});
            dispatch({type: "UPDATE_ITEM", payload: {id: form.itemId, cash: form.price}});
            showPP();

            if (!clientsPhone.includes(form.phone)) {
                userR = {
                    name: form.name,
                    address: form.address,
                    phone: form.phone,
                    orders: 1,
                    id: Number(form.phone),
                    status: 'good'
                };
                dispatch({type: "ADD_CLIENT", payload: userR});
                userR = {};

            } else {
                // dispatch({type: "CLIENT_ADD_ORDER", payload: Number(form.phone)});
            }
            setTimeout(() => {
                setForm({
                    ...form,
                    id: '',
                    name: '',
                    address: '',
                    phone: '',
                    item: '',
                    itemId: '',
                    startDate: '',
                    termDate: '',
                    fullDate: '',
                    price: '',
                    duty: '',
                });
                selectInput.current.reset();
            }, 500)
        };


// console.log((form.termDate - form.startDate) / 8.64e+7)
// console.log(new Date(form.startDate).toLocaleDateString() == new Date(currentTime).toLocaleDateString());

        return (
            <div className={q.newOrderMain}>
                <div className={popupShow
                    ? [q.newOrder, q.hidden].join(' ')
                    : q.newOrder
                }>
                    <h2>Заказ от {new Date(currentDate).toLocaleDateString()}</h2>
                    <form className={q.ordersForm} ref={selectInput}>
                        <input type="text"
                               name="name"
                               placeholder="Фамилия Имя"
                               value={form.name}
                               onInput={changeHandler}
                               autoComplete="off"
                               autoFocus
                        />
                        <input type="text"
                               name="address"
                               placeholder="Адрес"
                               value={form.address}
                               onInput={changeHandler}
                               autoComplete="off"
                               disabled={!form.name}
                        />
                        <input type="number"
                               name="phone"
                               placeholder="Номер телефона /291112233/"
                               value={form.phone}
                               onInput={changeHandler}
                               autoComplete="off"
                               disabled={!form.address}
                               maxLength={9}
                        />
                        <div className={
                            itField
                                ? [q.itemsField, q.opn].join(' ')
                                : q.itemsField
                        }>
                            {tempItems.length
                                ? tempItems.map(i => <div className={q.box} key={i.id}
                                                          onClick={() => handleClick(i)}>{i.title}</div>)
                                : <h4 style={{color: 'red'}}>Нет элементов удовлетворяющих поиск, либо позиция находится в аренде</h4>
                            }
                        </div>
                        <input type="text"
                               name="item"
                               placeholder="Предмет аренды"
                               value={form.item}
                               onInput={sort}
                               autoComplete="off"
                               disabled={!form.phone}
                        />
                        <div style={{position: 'relative'}}>
                            <ImHistory className={q.refresh} onClick={() => setForm({...form, item: ''})}/>
                        </div>
                        <select
                            name="termDate"
                            defaultValue="Срок аренды"
                            onChange={changeHandler}
                            disabled={!form.item}
                        >
                            {dataSelect.map((a, i) => (
                                <option key={i} value={a.value} disabled={a.disabled}>{a.title}</option>
                            ))}
                        </select>
                        <input type="number"
                               name="price"
                               placeholder="Стоимость аренды"
                               value={form.price}
                               onInput={changeHandler}
                               autoComplete="off"
                               disabled={!form.termDate || form.termDate === 'Срок аренды'}
                        />

                        <div className={q.buttonBlock}>
                            <span><label className={q.checkBox}>
                                <input
                                    type="checkbox"
                                    className={q.checkBoxInput}
                                    name='duty'
                                    onClick={changeHandler}
                                    disabled={mountPopup || form.termDate === 'Срок аренды' || !form.termDate}
                                />
                                <div className={q.checkBoxInputDiv}></div>
                            </label></span><span style={{margin: '0 auto 0 15px'}}>В долг</span>
                            <button className={q.button} onClick={() => createOrder()}
                                    disabled={mountPopup || form.termDate === 'Срок аренды' || !form.termDate}>
                                Оформить
                            </button>
                        </div>

                    </form>

                </div>
                {mountPopup &&
                <Popup sp={popupShow} showPP={showPP}>
                    <div className={q.popuItem}>
                        {/*<div className={q.popupItm}>*/}
                        {/*    Номер заказа: <strong>{form.id}</strong>*/}
                        {/*</div>*/}
                        <div className={q.popupItm}>
                            ФИО: <strong>{form.name}</strong>
                        </div>
                        <div className={q.popupItm}>
                            Адрес: <strong>{form.address}</strong>
                        </div>
                        <div className={q.popupItm}>
                            Телефон: <strong>+375{form.phone}</strong>
                        </div>
                        <div className={q.popupItm}>
                            Арендуют: <strong>{form.item}</strong>
                        </div>
                        <div className={q.popupItm}>
                            Стоимость: <strong>{form.price} {takeAMoney(form.price)}</strong>
                        </div>
                        <div className={q.popupItm}>
                            Дата возврата: <strong>{takeADate(form.termDate)}</strong>
                        </div>
                        {form.duty && <div className={q.popupItm}>
                            <strong style={{color: '#2468AD'}}> - БЕЗ ОПЛАТЫ - </strong>
                        </div>
                        }
                        <ImCheckmark className={q.ok} onClick={addOrder}/>
                        <ImCross className={q.clz} onClick={() => showPP(false)}/>
                    </div>
                </Popup>
                }
            </div>
        );
    }
;

export default New;