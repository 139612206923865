import React, {useState} from 'react';
import q from './StartPage.module.css'
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const StartPage = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [initial, setInitial] = useState({
        login: '',
        password: ''
    });

    const login = '1';
    const password = '1';


    const check = () => {
        if (initial.login === login && initial.password === password) {
            dispatch({type: 'LOGIN'});
            setInitial({
                login: '',
                password: ''
            });
            navigate('new');
        } else if (initial.login !== login && initial.pass !== password) {
            alert('Неверные данные');
            setInitial({
                login: '',
                password: ''
            });
            // dispatch({type: 'LOGIN_OUT'});
        } else {
            alert('Заполните поля')
        }
    };

    const inputs = [
        {
            type: 'text',
            id: 'login',
            autocomplete: 'off',
            title: 'Введите логин',
            placeholder: ' ',
            labelText: 'Введите логин',
        },
        {
            type: 'password',
            id: 'password',
            autocomplete: 'off',
            title: 'Введите пароль',
            placeholder: ' ',
            labelText: 'Введите пароль',
        }
    ];

    const changeHandler = (event) => {
        setInitial({...initial, [event.target.id]: event.target.value})
    };


    return (
        <div className={q.authForm}>
            <div className={q.authFormItems}>
                {/*<div className='form'>*/}
                {/*    <input type="text" placeholder='введите ваш логин' value={initLog}*/}
                {/*           onInput={(event) => setInitLog(event.target.value)} autoFocus/>*/}
                {/*    <input type="password" placeholder='введите ваш пароль' value={initPass}*/}
                {/*           onInput={(event) => setInitPass(event.target.value)}/>*/}

                {inputs.map(i => (
                    <div className={q.inputGroup} key={i.id}>
                        <input type={i.type}
                               id={i.id}
                               title={i.title}
                               autoComplete={i.autocomplete}
                               placeholder={i.placeholder}
                               value={initial[i.id]}
                               onInput={changeHandler}
                        />
                        <label htmlFor={i.id}>{i.labelText}</label>
                    </div>
                ))}

                <button onClick={check}>Войти</button>
            </div>
        </div>
    );
};

export default StartPage;