import './App.css';
import Panel from "./components/Panel/Panel";
import Main from "./components/Main/Main";
import React from "react";

function App() {

    return (
        <div className="app">
            <Main />
            <Panel />
        </div>
    );
}

export default App;
