//  const days = (arg) => {
//     const n = arg % 10;
//     if (arg >= 11 && arg <= 20) {
//         return 'дней'
//     } else if (n === 1) {
//         return 'день'
//     } else if (n >= 2 && n <= 4) {
//         return 'дня'
//     } else if (n >= 5 && n <= 9) {
//         return 'дней'
//     } else if (n === 0) {
//         return 'дней'
//     }
// };


const takeADay = (arg ) => {
    let n = Math.abs(arg);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return 'дней'
    }
    n %= 10;
    if (n === 1) {
        return 'день'
    }
    if (n >= 2 && n <= 4) {
        return 'дня'
    }
    return 'дней'
};

export default takeADay;
