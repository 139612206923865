import React, {useEffect, useState} from 'react';
import q from './Popup.module.css'


const Popup = ({children, sp, showPP}) => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (sp) {
            setShow(true)
        } else {
            setShow(false)
        }
    }, [sp]);




    return (
        <div className={show
            ? [q.popup, q.opnP].join(' ')
            : q.popup
        } title={'click here to close popup'}
             onClick={ () => showPP(false)}
        >
            <div className={show
                ? [q.popupItem, q.opn].join(' ')
                : q.popupItem
            } onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default Popup;