import React, {useState} from 'react';
import {useSelector} from "react-redux";
import q from './Clients.module.css'
import Popup from "../../UI/Popup/Popup";
import {ImCross} from "react-icons/im";
import takeAMoney from "../../js/takeAMoney";
import takeADate from "../../js/takeADate";
import takeADay from "../../js/takeADay";

const Clients = () => {
    const clients = useSelector(state => state.clients);
    const orders = useSelector(state => state.order);
    const ordersAct = orders.filter(order => order.active === true);
    const [popupShow, setPopupShow] = useState(false);
    const [mountPopup, setMountPopup] = useState(false);
    const [card, setCard] = useState('');
    const [ordersList, setOrdersList] = useState([]);

    const showPP = (arg) => {
        if (arg) {
            orders.sort((x, y) => y.id - x.id);
            setOrdersList(orders.filter(cl => cl.phone === arg));
            setCard(clients.filter(cl => cl.phone === arg)[0]);
            setMountPopup(true);
            setTimeout(() => setPopupShow(true), 10);
        } else {
            setPopupShow(false);
            setTimeout(() => {
                setMountPopup(false);
                setCard('');
                setOrdersList([]);
            }, 600);
        }
    };

    return (
        <>
            <div className={popupShow
                ? [q.clientsField, q.hidden].join(' ')
                : q.clientsField
            }
                 onClick={() => {
                     if (popupShow) {
                         showPP()
                     }
                 }}>
                {clients.length
                    ? clients.map(o => (
                        <div key={o.id} className={q.box} onClick={(e) => {
                            e.stopPropagation();
                            showPP(o.phone);
                        }}>
                            <div className={q.description}>
                                <span><strong>{o.name}</strong> </span>

                                <div>
                                    <span
                                        style={{color: 'green'}}>в аренде: <strong>{ordersAct.filter(ord => ord.phone === o.phone).length}</strong> ед.</span>
                                </div>
                            </div>
                        </div>
                    ))
                    : <p>Клиентов ещё нет</p>
                }

            </div>
            {mountPopup &&
            <Popup sp={popupShow} showPP={showPP}>
                <div className={q.popupItm}>
                    <ImCross className={q.clz} onClick={() => showPP(false)}/>
                    <div className={q.popupName}>{card.name}</div>
                    <div className={q.popupName}>адр: {card.address}</div>
                    <div className={q.popupName}>тел: +375{card.phone}</div>
                    <div className={q.popupName}>статус:
                        {card.status === 'good'
                            ? <span style={{color: 'green'}}> {card.status}</span>
                            : <span style={{color: 'red'}}> {card.status}</span>
                        }
                    </div>
                    <h4>Список заказов</h4>
                    <div className={q.ordersField}>
                        {ordersList.map(ord => (
                            <div className={q.orderItm} key={ord.id}>
                                <span>{takeADate(ord.startDate)}</span>
                                <span className={q.mstspn}>{ord.item}</span>
                                {!ord.add && <span
                                    className={q.mstspn}>{((ord.termDate - ord.startDate) / 8.64e+7) - 1} {takeADay((ord.termDate - ord.startDate) / 8.64e+7 - 1)}
                                </span>

                                } <span className={q.lstspn}>{ord.price} {takeAMoney(ord.price)}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </Popup>
            }
        </>
    );
};

export default Clients;