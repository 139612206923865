import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import q from './Storage.module.css'
import {BsCalendar2Plus} from "react-icons/bs";
import Popup from "../../UI/Popup/Popup";
import {ImCross} from "react-icons/im";


const Storage = () => {

    const [popupShow, setPopupShow] = useState(false);
    const [mountPopup, setMountPopup] = useState(false);

    const [destroy, setDestroy] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [price, setPrice] = useState('');
    const [newItem, setNewItem] = useState({
        id: '',
        title: '',
        price: '',
        totalCash: 0,
        busy: false,
        condition: true,
    });


    const items = useSelector(state => state.items);
    const orders = useSelector(state => state.order);
    const dispatch = useDispatch();


    const removeItemFunc = (arg) => {
        dispatch({type: 'REMOVE_ITEM', payload: arg.id});
        showPP();
    };
    const repairItemFunc = (arg) => {
        dispatch({type: "REPAIR_ITEM", payload: {id: arg.id, condition: true, price: Number(price)}});
        showPP();
    };

    const newItemFunc = () => {
        setNewItem({...newItem, id: Date.now()});
        setMountPopup(true);
        setTimeout(() => setPopupShow(true), 10);
    };

    const addNewItem = () => {
        dispatch({type: 'ADD_ITEM', payload: newItem});
        showPP();
    };


    const takeAMoney = (arg) => {
        return orders.filter(o => o.itemId === arg).map(i => i.price).reduce((a,b) => a + b,0)
    };

    const showPP = (arg) => {
        if (arg) {
            setCurrentItem(arg);
            setMountPopup(true);
            setTimeout(() => setPopupShow(true), 10);
        } else {
            setPopupShow(false);
            setTimeout(() => {
                setMountPopup(false);
                setDestroy(false);
                setCurrentItem(null);
                setPrice('');
                setNewItem({...newItem, id: '', price: '', title: ''});
            }, 500);
        }
    };

    return (
        <div className={q.storage}>
            <div className={q.addItem}>
                <BsCalendar2Plus className={q.addItemIco} onClick={newItemFunc}/>
            </div>
            <div className={q.items}>
                {items.length
                    ?
                    items.map(i => (
                        <div className={q.box} key={i.id} onClick={() => {
                            setDestroy(true);
                            showPP(i);
                        }}>
                            <div className={q.boxTitle}>{i.title}</div>
                            <div className={q.boxInfo}>
                                {!i.condition && <div style={{color: 'red'}}>Испорчено</div>}
                                <div className={q.boxInfoPrice}>себестоимость: {i.price}р., выручка: {takeAMoney(i.id)}р.
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <span> ...Пусто</span>
                }

            </div>
            {mountPopup &&
            <Popup sp={popupShow} showPP={showPP}>
                <div className={q.popupItm}>
                    <ImCross className={q.clz} onClick={() => showPP(false)}/>
                </div>
                {destroy
                    ? <div className={q.repairField}>
                        {!currentItem.condition &&
                        <div className={q.repair}>
                            <input
                                type="number"
                                placeholder='Стоимость ремонта'
                                value={price}
                                onInput={(e) => setPrice(e.target.value)}
                            />
                            <button className={q.repairItem} onClick={() => repairItemFunc(currentItem)}>Ремонт</button>
                        </div>
                        }
                        <button className={q.removeItem} onClick={() => removeItemFunc(currentItem)}>Списано</button>
                    </div>

                    : <div className={q.repairField}>
                        <div className={q.newInp}>
                            <input type="text" placeholder='Наименование' name='title' value={newItem.title}
                                   onInput={(e) => setNewItem({...newItem, [e.target.name]: e.target.value})}/>
                            <input type="number" placeholder='Стоимость' name='price' value={newItem.price}
                                   onInput={(e) => setNewItem({...newItem, [e.target.name]: Number(e.target.value)})}/>
                        </div>
                        <button className={q.newInpBtn} onClick={addNewItem}>Добавить</button>
                    </div>
                }

            </Popup>
            }
        </div>
    );
};

export default Storage;